//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    slide: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    clientOnly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    rootComponent: function () {
      const link = String(this.slide?.link || '');
      if (!link) {
        return "div"
      }
      const isExternalLink = Boolean(link.includes('https://') || link.includes('http://'));
      if (isExternalLink) {
        return "a"
      }
      return "nuxt-link"
    },

    clientOnlyComponent: function () {
      if (!this.clientOnly) {
        return "div"
      }
      return "client-only"
    },

    imageSrc: function () {
      if (Boolean(String(this.slide?.is_gif) === '1')) {
        return String(this.slide?.image_link)
      }
      return String(this.slide?.image)
    },
    imageComponent: function () {
      if (Boolean(String(this.slide?.is_gif) === '1')) {
        return "img"
      }
      return "sitis-img"
    },
  }
}
